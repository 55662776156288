function changeToTab(categorySlug) {
    document.querySelector(".tabs .tab.active").classList.remove("active");
    document.querySelector(".tab-content[aria-hidden='false']").setAttribute("aria-hidden", true);

    document.querySelector(".tabs .tab[data-category='" + categorySlug + "']").classList.add("active");
    document.querySelector(".tab-content#" + categorySlug).setAttribute("aria-hidden", false);
}

function toggleActiveTab(event) {
    if (dragging) return;

    var target = event.target.closest(".tabs .tab");
    if (target) {
        event.preventDefault();
        event.stopPropagation();

        changeToTab(target.getAttribute("data-category"));
    }
}

document.addEventListener("click", toggleActiveTab);
document.addEventListener("touchend", toggleActiveTab);

document.addEventListener("DOMContentLoaded", function () {
    if (window.location.hash) {
        changeToTab(window.location.hash.replace("#", ""));
    }
});