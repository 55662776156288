// window.addEventListener("scroll", function () {
//     var h = document.documentElement,
//         b = document.body,
//         st = 'scrollTop',
//         sh = 'scrollHeight';
//     var scrollPercent = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
//     if (scrollPercent < 10) {
//         scrollPercent = 10;
//     } else
//     if (scrollPercent > 90) {
//         scrollPercent = 90;
//     }
//     document.querySelector(".plane-scroll").style.top = scrollPercent + '%';
// });