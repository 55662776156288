/**
 *		TOGGLE MENU
 *		- Toggle main navigation on mobile resolutions (lower than breakpointToDesktopNav)
 */
function toggleMenu(event) {
	if (dragging) return;

	var target = event.target.closest(".toggle.menu");
	if (target) {
		event.preventDefault();
		event.stopPropagation();

		target.classList.toggle("on");
        target.parentNode.parentNode.querySelector(":scope .site-menu").classList.toggle("is-shown");
	}
}

/**
 *		TOGGLE CHILD MENUS
 *		- Toggle child navigation on mobile resolutions (lower than breakpointToDesktopNav)
 */
function toggleChildMenu(event) {
	if (dragging) return;

	var target = event.target.closest(".toggle.child");
	if (target) {
		event.preventDefault();
		event.stopPropagation();

		target.parentNode.classList.toggle("on");
		var siblingMenu = target.nextElementSibling;
		if (window.getComputedStyle(siblingMenu).display == "none") {
			siblingMenu.style.display = "block";
		} else {
			siblingMenu.style.display = "none";
		}
	}
}

/**
 *		TOGGLE DESKTOP CHILD MENUS
 *		- Prevent link click on Enter keydown
 *		- Open child menu on first touch or Enter keyup, follow link only if child menu is open
 */
function toggleDesktopChildMenu(event) {
	if (dragging) return;

	if (widthREMs >= breakpointToDesktopNav) {
		var target = event.target.closest(".site-menu ul li.has-children > a");
		if (target && event.type == "keydown" && event.key == "Enter") {
			event.preventDefault();
		} else
		if (target && (event.type == "touchend" || (event.type == "keyup" && event.key == "Enter"))) {
			event.stopPropagation();

			// Prevent default on anchor tag on first touch or Enter key
			if (!target.hasAttribute("data-is-open") || target.getAttribute("data-is-open") == "false") {
				event.preventDefault();

				// Hide other child navs, but not if they're an ancestor
				var menusWithChildren = document.querySelectorAll(".site-menu ul li.has-children");
				for (var i = 0; i < menusWithChildren.length; i++) {
					if (!menusWithChildren[i].contains(target)) {
						menusWithChildren[i].classList.remove("on");
						menusWithChildren[i].querySelector(".link-wrapper").classList.remove("is-shown");
						menusWithChildren[i].querySelector(".site-menu ul li.has-children > a").setAttribute("data-is-open", false);
					}
				}

				// Display child navigation on first touch or Enter key
				target.setAttribute("data-is-open", true);
				target.parentNode.classList.toggle("on");
				target.parentNode.querySelector(".link-wrapper").classList.add("is-shown");
			} else {
				target.setAttribute("data-is-open", false);
				target.click();
			}
		}
	}
}

/**
 *      TOGGLE NAV SEARCH
 */
function toggleNavSearch(event) {
    if (dragging) return;

    var target = event.target.closest(".toggle-search");
    if (target) {
        event.preventDefault();
        event.stopPropagation();

        target.classList.toggle("show-search");

        if (target.classList.contains("show-search")) {
            document.querySelector(".utility-menu .search-wrapper form input[name=keywords]").focus();

            var linksInMenusWithChildren = document.querySelectorAll(".site-menu ul li.has-children > a");
            for (var i = 0; i < linksInMenusWithChildren.length; i++) {
                linksInMenusWithChildren[i].setAttribute("data-is-open", false);
                linksInMenusWithChildren[i].parentNode.classList.remove("on");
                linksInMenusWithChildren[i].parentNode.querySelector(".link-wrapper").classList.remove("is-shown");
            }
        }
    }
}
document.addEventListener("click", toggleNavSearch);
document.addEventListener("touchend", toggleNavSearch);

/**
 *		HEADER / NAV
 *		- Toggle navigation by click/touch on mobile
 *		- Toggle navigation by mouse enter/leave on desktop
 */
// Mobile: Main navigation
document.addEventListener("click", toggleMenu);
document.addEventListener("touchend", toggleMenu);

// Mobile: Child navigation
document.addEventListener("click", toggleChildMenu);
document.addEventListener("touchend", toggleChildMenu);

// Desktop: Close all open child menus on touchend
document.addEventListener("touchend", function (event) {
    if (widthREMs >= breakpointToDesktopNav) {
        var target = event.target.closest(".site-menu ul li.has-children a");
        // If target is direct child of li.has-children, then follow link
        if (target && !target.parentNode.classList.contains("has-children")) {
        } else
        // Otherwise, stopPropagation and close all menus
        if (!target) {
            event.stopPropagation();
            var linksInMenusWithChildren = document.querySelectorAll(".site-menu ul li.has-children > a");
            for (var i = 0; i < linksInMenusWithChildren.length; i++) {
                linksInMenusWithChildren[i].setAttribute("data-is-open", false);
                linksInMenusWithChildren[i].parentNode.classList.remove("on");
                linksInMenusWithChildren[i].parentNode.querySelector(".link-wrapper").classList.remove("is-shown");
            }
        }
    }
});

// Desktop: Open child menu on first touch or Enter keyup, follow link only if child menu is open
document.addEventListener("touchend", toggleDesktopChildMenu);
document.addEventListener("keydown", toggleDesktopChildMenu);
document.addEventListener("keyup", toggleDesktopChildMenu);

document.addEventListener("DOMContentLoaded", function () {
	// Desktop: Display child menu when mouse enters, and hide when mouse leaves
	var menusWithChildren = document.querySelectorAll(".site-menu ul li.has-children");
	for (var i = 0; i < menusWithChildren.length; i++) {
		// Show on enter
		menusWithChildren[i].addEventListener("mouseenter", function (event) {
			if (widthREMs >= breakpointToDesktopNav) {
				event.target.classList.add("on");
				event.target.querySelector(".link-wrapper").classList.add("is-shown");
			}
		});
		// Hide on leave
		menusWithChildren[i].addEventListener("mouseleave", function (event) {
			if (widthREMs >= breakpointToDesktopNav) {
				event.target.classList.remove("on");
				event.target.querySelector(".link-wrapper").classList.remove("is-shown");
			}
		});
	}
});

/**
 *      STICKY NAV
 */
function stickyNav() {
    if (window.scrollY > 300) {
        document.querySelector(".sticky-nav").setAttribute("aria-hidden", false);
    } else {
        document.querySelector(".sticky-nav").setAttribute("aria-hidden", true);
    }
}
document.addEventListener("DOMContentLoaded", stickyNav);
window.addEventListener("scroll", stickyNav);