// Get the width (in EMs/REMs) at which the full menu should be shown
updateBreakpointToDesktopNav();
window.updateBreakpointToDesktopNav = updateBreakpointToDesktopNav;

/**
 *		UPDATE BREAKPOINT TO DESKTOP NAV
 *		- Updates the breakpointToDesktopNav variable when the fontSize option is changed
 */
function updateBreakpointToDesktopNav() {
	for (var i = 0; i < document.styleSheets.length; i++) {
		if (document.styleSheets[i].title == 'criticalCss') {
			var sheetRules = document.styleSheets[i].cssRules;
			for (var j = 0; j < sheetRules.length; j++) {
				if (sheetRules[j].selectorText == "html #widthREMs") {
					window.breakpointToDesktopNav = parseFloat(sheetRules[j].style.width.replace(/[^0-9.]/g, ''));
				}
			}
		}
	}
}