// Keep track of width in REMs
window.htmlStyles = window.getComputedStyle(document.getElementsByTagName("html")[0]);
window.widthREMs = window.innerWidth / parseFloat(htmlStyles.fontSize);
updateWidthREMS();
window.updateWidthREMS = updateWidthREMS;

/**
 *		- Updates the widthREMs variable on orientation change or resize (event listeners below)
 *		- Hides any toggle navigation if resizing greater than/equal to breakpointToDesktopNav
 */
function updateWidthREMS() {
	widthREMs = window.innerWidth / parseFloat(htmlStyles.fontSize);

    if (widthREMs >= window.breakpointToDesktopNav) {
        document.querySelector(".toggle.menu").classList.remove("on");
        var navsWithChildren = document.querySelectorAll(".site-nav .site-menu .has-children");
        for (var i = 0; i < navsWithChildren.length; i++) {
            navsWithChildren[i].classList.remove("on");
            navsWithChildren[i].querySelector(".site-nav .site-menu .has-children > .link-wrapper").style.display = "none";
        }
    }
}

/**
 *		- Call updateWidthREMS on orientationchange and resize events
 */
window.addEventListener("orientationchange", updateWidthREMS);
window.addEventListener("resize", updateWidthREMS);