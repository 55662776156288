function toggleAccordion(event) {
	if (dragging) return;

	var target = event.target.closest(".frequently-asked-questions a.toggler");
	if (target) {
		event.preventDefault();

		target.classList.toggle("toggled");
	}
}

document.addEventListener("click", toggleAccordion);
document.addEventListener("touchend", toggleAccordion);