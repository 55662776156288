import { tns } from 'tiny-slider/src/tiny-slider'

window.sliders = [];

// Galleries
let galleries = document.querySelectorAll('.block.gallery.carousel')
for (let index = 0; index < galleries.length; index++) {
	const element = galleries[index];
	window.sliders['gallery' + element.getAttribute("data-gallery")] = tns({
		container: element.querySelector(".slider"),
		items: 1,
		gutter: 14,
		edgePadding: 60,
		controls: false,
		navContainer: element.querySelector(":scope .controls"),
		autoplay: true,
		autoplayButtonOutput: false,
		loop: false,
		responsive: {
			640: {
				gutter: 28,
				edgePadding: 120
			},
			960: {
				gutter: 42,
				edgePadding: 180
			}
		}
	});
}

// Text w/Testimonials
let textWTestimonials = document.querySelectorAll('.block.text-testimonials')
for (let index = 0; index < textWTestimonials.length; index++) {
	const element = textWTestimonials[index];
	window.sliders['textWTestimonial' + element.getAttribute("data-block")] = tns({
        container: element.querySelector(".slider"),
        mode: 'gallery',
		items: 1,
        controlsContainer: element.querySelector(":scope .controls"),
		nav: false,
		autoplay: true,
		autoplayButtonOutput: false,
        loop: true,
        onInit: function (slider) {
            slider.controlsContainer.setAttribute('tabindex', -1);
            slider.controlsContainer.removeAttribute('aria-label');
            slider.nextButton.setAttribute('tabindex', 0);
            slider.nextButton.setAttribute('aria-label', 'View next slide');
            slider.nextButton.removeAttribute('aria-controls');
            slider.prevButton.setAttribute('tabindex', 0);
            slider.prevButton.setAttribute('aria-label', 'View previous slide');
            slider.prevButton.removeAttribute('aria-controls');
        }
	});
}