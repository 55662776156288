/**
 *		- Update html or body attribute to chosen font size or contrast mode, respectively
 *		- Set/update browser cookie
 */
function updateAccessibilityOption(event) {
	if (dragging) return;

	var target = event.target.closest("a[data-font-size], a[data-contrast-mode]");
	if (target) {
		// Prevent normal anchor tag behavior (page will reload as a fallback if javascript is disabled)
		event.preventDefault();

		// Update cookies and html/body element data attributes
		if (target.hasAttribute("data-font-size")) {
			var allLinks = document.querySelectorAll("a[data-font-size]:not([data-font-size=" + target.getAttribute("data-font-size") + "])");
			for (var link of allLinks) {
				link.classList.remove("active");
			}
			target.classList.add("active");
			document.documentElement.setAttribute("data-font-size", target.getAttribute("data-font-size"));
			document.cookie = "fontSize=" + target.getAttribute("data-font-size") + expires + "; path=/; samesite=strict";

            updateBreakpointToDesktopNav();
            updateWidthREMS();
		}
		else if (target.hasAttribute("data-contrast-mode")) {
			var allLinks = document.querySelectorAll("a[data-contrast-mode]:not([data-contrast-mode=" + target.getAttribute("data-contrast-mode") + "])");
			for (var link of allLinks) {
				link.classList.remove("active");
			}
			target.classList.add("active");
			document.body.setAttribute("data-contrast-mode", target.getAttribute("data-contrast-mode"));
			document.cookie = "contrastMode=" + target.getAttribute("data-contrast-mode") + expires + "; path=/; samesite=strict";
		}
	}
}

/**
 *		- Toggle font size or contrast mode menus
 *		- Update font size or contrast mode and update browser cookie to remember setting
 *		- Toggle accessibility toolbar and update browser cookie to remember setting
 */

// Update font size or contrast mode
document.addEventListener("click", updateAccessibilityOption);
document.addEventListener("touchend", updateAccessibilityOption);