/**
 *		- Close the current site-wide alert when clicking/touching the close button
 *		- Sets/updates a browser cookie with the current alert ID
 */
function closeSitewideAlert(event) {
	if (dragging) return;

	let date = new Date();
	date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
	let expires = "; expires=" + date.toGMTString();

	var target = event.target.closest("[data-alert-id]");
	if (target) {
		event.preventDefault();

		// Current value of sitewideAlert cookie?
		var currentIndex = document.cookie.indexOf("sitewideAlert");
		if (currentIndex >= 0) {
			var nextSemiColon = document.cookie.indexOf(";", currentIndex);
			if (nextSemiColon > currentIndex) {
				var sitewideAlertCookie = document.cookie.substr(currentIndex, nextSemiColon - currentIndex);
			} else {
				var sitewideAlertCookie = document.cookie.substr(currentIndex);
			}
			var sitewideAlertValue = sitewideAlertCookie.substr(sitewideAlertCookie.indexOf("=") + 1);
			document.cookie = "sitewideAlert=" + sitewideAlertValue + "|" + target.getAttribute("data-alert-id") + expires + "; path=/";
		} else {
			document.cookie = "sitewideAlert=" + target.getAttribute("data-alert-id") + expires + "; path=/";
		}

		document.getElementById("alert").style.display = "none"; // IE11
		document.getElementById("alert").remove();
	}
}

document.addEventListener("click", closeSitewideAlert);
document.addEventListener("touchend", closeSitewideAlert);